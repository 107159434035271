<template>
  <page>
    <div class="pageJoin">
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <input type="text" v-model="code" placeholder="输入公司码" class="companyCode" />

      <button @click='submitFn' class="btn btn_radius theme_bg margin_auto joinBtn" :class="{theme_dis_bg: !code}">加入公司</button>
    </div>
  </page>
</template>

<script>
import api from '@/api/user'
export default {
  data: ()=> ({
    code: ''
  }),
  methods: {
    submitFn() {
      if (this.code) {
        api.joinCompany({
          companyCode: this.code
        }).then(res => {
          if (res.data) {
            this.$toast(res.data.msg)
            this.code = ''
            this.$router.back()
          }
        })
      } else {
        this.$toast('请输入公司码')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pageJoin {
  padding: 16px;
  padding-top: 32px;
}
.companyCode {
  border: none;
  outline: none;
  font-size: 15px;
  color: #333;
  width: 100%;
  padding-bottom: 17px;
  border-bottom: 1px solid #eee;
}
.joinBtn {
  margin-top: 189px;
  width: 80%;
}
</style>